
(function () {

    function disableBackNavigation() {
        let shouldDisableBackNavigation = document.querySelectorAll('[disable-back-navigation]')
        if (shouldDisableBackNavigation) {
            history.pushState(null, null, location.href);
            window.onpopstate = function () {
                history.go(1);
            };
        }
    }

    function init() {
        disableBackNavigation();
    }

    init();

})();